<template>
  <div class="white print-style" v-if="authTokenSet">
    <div class="pa-8 rounded-xl mb-4">
      <h1 class="mb-4">Údaje o pacientovi</h1>

      <div>
        Jméno:
        {{ patient.fullName }}
      </div>
      <div>
        Email:
        {{ patient.email }}
      </div>
      <div>
        Rok narození:
        {{ patient.birthYear }}
      </div>

      <div>
        Report obsahuje data: od {{ getCzechDateWithoutTime(from) }} do
        {{ getCzechDateWithoutTime(to) }}
      </div>

      <div>Report vygenerován: {{ getCzechDate(new Date()) }}</div>
    </div>

    <div v-for="(item, i) in items" :key="i">
      <div v-if="showComponent(item)" class="white pa-8 rounded-xl mb-4">
        <h1 v-html="item.title" class="mb-4"></h1>

        <component :is="item.component" :patient="patient" print></component>
        <div
          v-if="item.component === 'PatientRybelsusTab' && showComponent(item)"
        >
          <CalendarsTreatment />
        </div>
        <div v-if="item.component === 'PatientStepsTab'" class="mt-12">
          <CalendarsSteps :patient="patient" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PatientRybelsusTab from "@/components/tabs/PatientRybelsusTab.vue";
import PatientWeightTab from "@/components/tabs/PatientWeightTab.vue";
import PatientHemoglobinTab from "@/components/tabs/PatientHemoglobinTab.vue";
import PatientStepsTab from "@/components/tabs/PatientStepsTab.vue";
import AppDialog from "@/components/elements/AppDialog.vue";
import useApi from "@/use/apiCall";
import useFormatDate from "@/use/formatDate";
import PatientStatusIcon from "@/components/icons/PatientStatusIcon.vue";
import CalendarsTreatment from "@/components/calendars/treatment.vue";
import CalendarsSteps from "@/components/calendars/steps.vue";

export default {
  components: {
    AppDialog,
    PatientRybelsusTab,
    PatientWeightTab,
    PatientStepsTab,
    PatientStatusIcon,
    PatientHemoglobinTab,
    CalendarsTreatment,
    CalendarsSteps,
  },
  setup() {
    const { getData } = useApi();
    const { getCzechDate, getCzechDateWithoutTime } = useFormatDate();

    return {
      getData,
      getCzechDate,
      getCzechDateWithoutTime,
    };
  },

  async mounted() {
    this.authToken = this.$route.query.authToken;

    const userInfo = {
      user: {},
      auth: {
        accessToken: this.authToken,
      },
    };

    this.$store.commit("setUserInfo", userInfo);

    this.authTokenSet = true;
    const patientId = Number(this.$route.params.id);
    const response = await this.getData(`/users/${patientId}`);
    this.patient = response.user;
  },

  computed: {
    patientActivityStatus() {
      return this.$store.getters.getPatientActivityStatus;
    },
    patientAge() {
      return new Date().getFullYear() - this.patient.birthYear;
    },

    from() {
      return this.$route.query.from;
    },

    to() {
      return this.$route.query.to;
    },
  },

  data() {
    return {
      patient: {},
      tab: 0,
      show: false,
      showCancelCareDialog: false,
      authTokenSet: false,
      authToken: null,
      items: [
        {
          title: "Rybelsus<sup>®</sup>",
          component: "PatientRybelsusTab",
        },
        {
          title: "Hmotnost",
          component: "PatientWeightTab",
        },
        {
          title: "Kroky",
          component: "PatientStepsTab",
        },
        {
          title: "Glykovaný hemoglobin",
          component: "PatientHemoglobinTab",
        },
      ],
    };
  },

  methods: {
    showComponent(item) {
      console.log(this.$route.query[item.component] === "true", item.component);
      return this.$route.query[item.component] === "true";
    },
    async fetchPatientDetail() {
      const patientId = Number(this.$route.params.id);
      const { patient } = await this.getData(`patients/${patientId}`);
      this.patient = patient;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tabs-items {
  background-color: white !important;
}

.v-tab--active {
  background-color: white;
  border-radius: 0.5rem 0.5rem 0 0;
}

.icon-wrapper {
  position: relative;
}

.patient-status {
  position: absolute;
  bottom: 1rem;
  right: 1.2rem;
}

@media print {
  .no-break {
    page-break-inside: avoid; /* Avoid breaking inside the element */
    break-inside: avoid; /* Ensures compatibility with modern browsers */
    display: block;
  }

  .page-start {
    page-break-before: always; /* Start a new page if needed */
  }

  .new-page {
    page-break-before: always; /* For older browsers */
    break-before: page; /* Modern standard */
  }
}
</style>
