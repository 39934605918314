<template>
  <v-form class="export-patient-data-form" ref="form">
    <div>Zvolte údaje, které chcete zahrnout do exportu:</div>
    <div v-for="(section, i) in sections" :key="i">
      <v-checkbox
        :label="section.label"
        v-model="section.value"
        hide-details
      ></v-checkbox>
    </div>

    <div class="red--text mt-4" v-if="noSectionSelected">
      Je potřeba vybrat alespoň jeden údaj pro export.
    </div>

    <v-row class="mt-2">
      <v-col>
        <v-menu
          ref="menuFrom"
          v-model="fromMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <div>
              <div>
                <small class="grey--text">Data od měsíce:</small>
              </div>

              <v-text-field
                outlined
                v-model="formattedFrom"
                placeholder="Vyberte měsíce"
                prepend-inner-icon="mdi-calendar"
                readonly
                :rules="[rules.required]"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </div>
          </template>

          <v-date-picker
            locale="cs-CZ"
            v-model="from"
            type="month"
            @change="save"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          ref="menuTo"
          v-model="toMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <div>
              <div>
                <small class="grey--text">Data do měsíce:</small>
              </div>

              <v-text-field
                outlined
                v-model="formattedTo"
                placeholder="Vyberte měsíce"
                prepend-inner-icon="mdi-calendar"
                readonly
                :rules="[rules.required]"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </div>
          </template>

          <v-date-picker
            locale="cs-CZ"
            v-model="to"
            type="month"
            @change="save"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <div v-if="fromIsBeforeTo" class="red--text mb-10">
      Datum od nesmí být později než datum do
    </div>
    <div class="mt-2">
      <ButtonElement
        block
        :loading="generateLoading"
        text="Stáhnout"
        @click="handleDownloadReport"
      ></ButtonElement>
    </div>
  </v-form>
</template>

<script>
import ButtonElement from "../elements/ButtonElement.vue";
import useApi from "@/use/apiCall";
import validations from "../../use/validations";

export default {
  components: {
    ButtonElement,
  },

  setup() {
    const { getData } = useApi();

    return {
      getData,
    };
  },

  data() {
    return {
      rules: validations,
      from: "",
      to: "",
      formattedFrom: "",
      formattedTo: "",
      generateLoading: false,
      fromMenu: false,
      toMenu: false,
      sections: [
        { label: "Léčba Rybelsusem®", value: true, name: "PatientRybelsusTab" },
        { label: "Hmotnost", value: true, name: "PatientWeightTab" },
        { label: "Kroky", value: true, name: "PatientStepsTab" },
        {
          label: "Glykovaný hemoglobin",
          value: true,
          name: "PatientHemoglobinTab",
        },
      ],
    };
  },

  computed: {
    noSectionSelected() {
      return !this.sections.some((section) => section.value);
    },

    fromIsBeforeTo() {
      if (this.from && this.to) {
        return new Date(this.from) > new Date(this.to);
      }

      return false;
    },
  },
  methods: {
    formatDate(date) {
      const formattedDate = new Date(date);

      return formattedDate.toLocaleDateString("cs-CZ", {
        year: "numeric",
        month: "long",
      });
    },

    save(date) {
      if (!date) {
        return;
      }
      this.$refs.menuFrom.save(date);
      this.$refs.menuTo.save(date);

      if (this.to) {
        this.formattedTo = this.formatDate(this.to);
      }

      if (this.from) {
        this.formattedFrom = this.formatDate(this.from);
      }
    },

    convertArrayToURLParams(arr) {
      return arr
        .map(
          (item) =>
            `${encodeURIComponent(item.name)}=${encodeURIComponent(item.value)}`
        )
        .join("&");
    },

    async handleDownloadReport() {
      this.generateLoading = true;

      try {
        if (this.noSectionSelected) {
          return;
        }
        if (this.fromIsBeforeTo) {
          return;
        }

        const patientId = Number(this.$route.params.id);

        const includedSections = this.convertArrayToURLParams(this.sections);

        // Calculate the last day of the month for this.to
        const [year, month] = this.to.split("-");
        const lastDay = new Date(parseInt(year), parseInt(month), 0).getDate(); // Adjust month to be zero-based

        const formattedTo = `${this.to}-${lastDay}`;
        const formattedFrom = `${this.from}-01`;

        // Fetch the binary data
        const response = await this.getData(
          `exports/patient-report?patientId=${patientId}&relativeUrl=${encodeURIComponent(
            `/pdf-report/${patientId}?from=${formattedFrom}&to=${formattedTo}&${includedSections}`
          )}`,
          { responseType: "arraybuffer" }, // Ensure response is in binary format
          true
        );

        console.log(response);

        if (!response) {
          return;
        }

        // Convert the binary data to a Blob
        const blob = new Blob([response], { type: "application/pdf" });

        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element and trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = `Patient_Report_${patientId}.pdf`;
        document.body.appendChild(link);
        link.click();

        // Clean up the temporary URL
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading the report:", error);
      } finally {
        this.generateLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
